@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:100,100i,300,300i,400,400i,500,500i,700,700i);





body {
  font-style: normal;
  line-height: 1.5;
}
.mbr-section-title {
  font-style: normal;
  line-height: 1.2;
}
.mbr-section-subtitle {
  line-height: 1.3;
}
.mbr-text {
  font-style: normal;
  line-height: 1.6;
}
.display-1 {
  font-family: 'Rubik', sans-serif;
  font-size: 4.25rem;
}
.display-1 > .mbr-iconfont {
  font-size: 6.8rem;
}
.display-2 {
  font-family: 'Rubik', sans-serif;
  font-size: 3rem;
}
.display-2 > .mbr-iconfont {
  font-size: 4.8rem;
}
.display-4 {
  font-family: 'Roboto Mono', monospace;
  font-size: 1rem;
}
.display-4 > .mbr-iconfont {
  font-size: 1.6rem;
}
.display-5 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
}
.display-5 > .mbr-iconfont {
  font-size: 2.4rem;
}
.display-7 {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
}
.display-7 > .mbr-iconfont {
  font-size: 1.6rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.4rem;
    font-size: calc( 2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.8rem;
    font-size: calc( 1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 1.2rem;
    font-size: calc( 1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  font-weight: 500;
  border-width: 2px;
  font-style: normal;
  letter-spacing: 1px;
  margin: .4rem .8rem;
  white-space: normal;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1rem 3rem;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}
.btn-sm {
  font-weight: 500;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0.6rem 1.5rem;
  border-radius: 3px;
}
.btn-md {
  font-weight: 500;
  letter-spacing: 1px;
  margin: .4rem .8rem !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1rem 3rem;
  border-radius: 3px;
}
.btn-lg {
  font-weight: 500;
  letter-spacing: 1px;
  margin: .4rem .8rem !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1.2rem 3.2rem;
  border-radius: 3px;
}
.bg-primary {
  background-color: #149dcc !important;
}
.bg-success {
  background-color: #f7ed4a !important;
}
.bg-info {
  background-color: #82786e !important;
}
.bg-warning {
  background-color: #879a9f !important;
}
.bg-danger {
  background-color: #b1a374 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #149dcc !important;
  border-color: #149dcc !important;
  color: #ffffff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
  color: #ffffff !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-info,
.btn-info:active {
  background-color: #82786e !important;
  border-color: #82786e !important;
  color: #ffffff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-success,
.btn-success:active {
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
  color: #3f3c03 !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #879a9f !important;
  border-color: #879a9f !important;
  color: #ffffff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
  color: #ffffff !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-white {
  color: #333333 !important;
}
.btn-white,
.btn-white:active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #808080 !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-black,
.btn-black:active {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background: none;
  border-color: #0b566f;
  color: #0b566f;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #ffffff;
  background-color: #149dcc;
  border-color: #149dcc;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #149dcc !important;
  border-color: #149dcc !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background: none;
  border-color: #cc0033;
  color: #cc0033;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background: none;
  border-color: #4b453f;
  color: #4b453f;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #ffffff;
  background-color: #82786e;
  border-color: #82786e;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #82786e !important;
  border-color: #82786e !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background: none;
  border-color: #d2c609;
  color: #d2c609;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #3f3c03;
  background-color: #f7ed4a;
  border-color: #f7ed4a;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #3f3c03 !important;
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background: none;
  border-color: #55666b;
  color: #55666b;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffffff;
  background-color: #879a9f;
  border-color: #879a9f;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #879a9f !important;
  border-color: #879a9f !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background: none;
  border-color: #7a6e45;
  color: #7a6e45;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ffffff;
  background-color: #b1a374;
  border-color: #b1a374;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background: none;
  border-color: #000000;
  color: #000000;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-white-outline,
.btn-white-outline:active,
.btn-white-outline.active {
  background: none;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.text-primary {
  color: #149dcc !important;
}
.text-secondary {
  color: #ff3366 !important;
}
.text-success {
  color: #f7ed4a !important;
}
.text-info {
  color: #82786e !important;
}
.text-warning {
  color: #879a9f !important;
}
.text-danger {
  color: #b1a374 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0b566f !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #cc0033 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #d2c609 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #4b453f !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #55666b !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #7a6e45 !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #b3b3b3 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #4d4d4d !important;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #82786e;
}
.alert-warning {
  background-color: #879a9f;
}
.alert-danger {
  background-color: #b1a374;
}
.mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: none !important;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}
.mbr-gallery-filter li.active .btn {
  background-color: #149dcc;
  border-color: #149dcc;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.nav-tabs .nav-link {
  border-radius: 100px !important;
}
.btn-form {
  border-radius: 0;
}
.btn-form:hover {
  cursor: pointer;
}
a,
a:hover {
  color: #149dcc;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #b4e6f8;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #beb8b2;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ced6d8;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #dfd9c6;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
#scrollToTop a i:before {
  content: '';
  position: absolute;
  height: 40%;
  top: 25%;
  background: #fff;
  width: 2px;
  left: calc(50% - 1px);
}
#scrollToTop a i:after {
  content: '';
  position: absolute;
  display: block;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 40%;
  height: 40%;
  left: 30%;
  bottom: 30%;
  transform: rotate(135deg);
}
/* Others*/
.note-check a[data-value=Rubik] {
  font-style: normal;
}
.mbr-arrow a {
  color: #ffffff;
}
@media (max-width: 767px) {
  .mbr-arrow {
    display: none;
  }
}
.form-control-label {
  position: relative;
  cursor: pointer;
  margin-bottom: .357em;
  padding: 0;
}
.alert {
  color: #ffffff;
  border-radius: 0;
  border: 0;
  font-size: .875rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative;
}
.alert.alert-form::after {
  background-color: inherit;
  bottom: -7px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  transform: rotate(45deg);
  width: 14px;
}
.form-control {
  background-color: #f5f5f5;
  box-shadow: none;
  color: #565656;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  line-height: 1.43;
  min-height: 3.5em;
  padding: 1.07em .5em;
}
.form-control > .mbr-iconfont {
  font-size: 1.6rem;
}
.form-control,
.form-control:focus {
  border: 1px solid #e8e8e8;
}
.form-active .form-control:invalid {
  border-color: red;
}
.mbr-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
blockquote {
  font-style: italic;
  padding: 10px 0 10px 20px;
  font-size: 1.09rem;
  position: relative;
  border-color: #149dcc;
  border-width: 3px;
}
ul,
ol,
pre,
blockquote {
  margin-bottom: 2.3125rem;
}
pre {
  background: #f4f4f4;
  padding: 10px 24px;
  white-space: pre-wrap;
}
.inactive {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  user-drag: none;
}
.mbr-section__comments .row {
  justify-content: center;
}
/* Forms */
.mbr-form .btn {
  margin: .4rem 0;
}
.mbr-form .input-group-btn a.btn {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn a.btn:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.mbr-form .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
  padding: 1rem 3rem;
}
.mbr-form .input-group-btn button[type="submit"]:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.form2 .form-control {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.form2 .input-group-btn a.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form2 .input-group-btn button[type="submit"] {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form3 input[type="email"] {
  border-radius: 100px !important;
}
@media (max-width: 349px) {
  .form2 input[type="email"] {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn a.btn {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn button[type="submit"] {
    border-radius: 100px !important;
  }
}
@media (max-width: 767px) {
  .btn {
    font-size: .75rem !important;
  }
  .btn .mbr-iconfont {
    font-size: 1rem !important;
  }
}
/* Social block */
.btn-social {
  font-size: 20px;
  border-radius: 50%;
  padding: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  position: relative;
  border: 2px solid #c0a375;
  border-color: #149dcc;
  color: #232323;
  cursor: pointer;
}
.btn-social i {
  top: 0;
  line-height: 44px;
  width: 44px;
}
.btn-social:hover {
  color: #fff;
  background: #149dcc;
}
.btn-social + .btn {
  margin-left: .1rem;
}
/* Footer */
.mbr-footer-content li::before,
.mbr-footer .mbr-contacts li::before {
  background: #149dcc;
}
.mbr-footer-content li a:hover,
.mbr-footer .mbr-contacts li a:hover {
  color: #149dcc;
}
.footer3 input[type="email"],
.footer4 input[type="email"] {
  border-radius: 100px !important;
}
.footer3 .input-group-btn a.btn,
.footer4 .input-group-btn a.btn {
  border-radius: 100px !important;
}
.footer3 .input-group-btn button[type="submit"],
.footer4 .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
}
/* Headers*/
.header13 .form-inline input[type="email"],
.header14 .form-inline input[type="email"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="text"],
.header14 .form-inline input[type="text"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="tel"],
.header14 .form-inline input[type="tel"] {
  border-radius: 100px;
}
.header13 .form-inline a.btn,
.header14 .form-inline a.btn {
  border-radius: 100px;
}
.header13 .form-inline button,
.header14 .form-inline button {
  border-radius: 100px !important;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
.navbar-toggler {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  -webkit-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.navbar-toggler-left {
  position: absolute;
  left: 1rem;
}
.navbar-toggler-right {
  position: absolute;
  right: 1rem;
}
@media (max-width: 575px) {
  .navbar-toggleable .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-toggleable {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-toggleable-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-sm > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-toggleable-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-sm > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-md > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-toggleable-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-md > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-md .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199px) {
  .navbar-toggleable-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-lg > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-toggleable-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-lg > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable-lg .navbar-toggler {
    display: none;
  }
}
.navbar-toggleable-xl {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggleable-xl .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-toggleable-xl > .container {
  padding-right: 0;
  padding-left: 0;
}
.navbar-toggleable-xl .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-toggleable-xl .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}
.navbar-toggleable-xl > .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggleable-xl .navbar-collapse {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
}
.navbar-toggleable-xl .navbar-toggler {
  display: none;
}
.card-img {
  width: auto;
}
.menu .navbar.collapsed:not(.beta-menu) {
  flex-direction: column;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.note-air-layout .dropup .dropdown-menu,
.note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: initial !important;
}
html,
body {
  height: auto;
  min-height: 100vh;
}
.dropup .dropdown-toggle::after {
  display: none;
}
.cid-qR4z4bOPiM .navbar {
  padding: .5rem 0;
  background: #465052;
  transition: none;
  min-height: 77px;
}
.cid-qR4z4bOPiM .navbar-dropdown.bg-color.transparent.opened {
  background: #465052;
}
.cid-qR4z4bOPiM a {
  font-style: normal;
}
.cid-qR4z4bOPiM .nav-item span {
  padding-right: 0.4em;
  line-height: 0.5em;
  vertical-align: text-bottom;
  position: relative;
  text-decoration: none;
}
.cid-qR4z4bOPiM .nav-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0 !important;
  margin: 0rem .65rem !important;
}
.cid-qR4z4bOPiM .nav-item:focus,
.cid-qR4z4bOPiM .nav-link:focus {
  outline: none;
}
.cid-qR4z4bOPiM .btn {
  padding: 0.4rem 1.5rem;
  display: inline-flex;
  align-items: center;
}
.cid-qR4z4bOPiM .btn .mbr-iconfont {
  font-size: 1.6rem;
}
.cid-qR4z4bOPiM .menu-logo {
  margin-right: auto;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand {
  display: flex;
  margin-left: 5rem;
  padding: 0;
  transition: padding .2s;
  min-height: 3.8rem;
  align-items: center;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand .navbar-caption-wrap {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  word-break: break-word;
  min-width: 7rem;
  margin: .3rem 0;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
  line-height: 1.2rem !important;
  padding-right: 2rem;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand .navbar-logo {
  font-size: 4rem;
  transition: font-size 0.25s;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand .navbar-logo img {
  display: flex;
}
.cid-qR4z4bOPiM .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
  transition: font-size 0.25s;
}
.cid-qR4z4bOPiM .navbar-toggleable-sm .navbar-collapse {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding-right: 5rem;
  width: auto;
}
.cid-qR4z4bOPiM .navbar-toggleable-sm .navbar-collapse .navbar-nav {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding-left: 0;
}
.cid-qR4z4bOPiM .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
  -webkit-align-self: center;
  align-self: center;
}
.cid-qR4z4bOPiM .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
  padding-left: 0;
  padding-bottom: 0;
}
.cid-qR4z4bOPiM .dropdown .dropdown-menu {
  background: #465052;
  display: none;
  position: absolute;
  min-width: 5rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  text-align: left;
}
.cid-qR4z4bOPiM .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}
.cid-qR4z4bOPiM .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-qR4z4bOPiM .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
}
.cid-qR4z4bOPiM .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-qR4z4bOPiM .navbar-toggleable-sm.opened:after {
  position: absolute;
  width: 100vw;
  height: 100vh;
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  z-index: 1000;
}
.cid-qR4z4bOPiM .navbar.navbar-short {
  min-height: 60px;
  transition: all .2s;
}
.cid-qR4z4bOPiM .navbar.navbar-short .navbar-toggler-right {
  top: 20px;
}
.cid-qR4z4bOPiM .navbar.navbar-short .navbar-logo a {
  font-size: 2.5rem !important;
  line-height: 2.5rem;
  transition: font-size 0.25s;
}
.cid-qR4z4bOPiM .navbar.navbar-short .navbar-logo a .mbr-iconfont {
  font-size: 2.5rem !important;
}
.cid-qR4z4bOPiM .navbar.navbar-short .navbar-logo a img {
  height: 3rem !important;
}
.cid-qR4z4bOPiM .navbar.navbar-short .navbar-brand {
  min-height: 3rem;
}
.cid-qR4z4bOPiM button.navbar-toggler {
  width: 31px;
  height: 18px;
  cursor: pointer;
  transition: all .2s;
  top: 1.5rem;
  right: 1rem;
}
.cid-qR4z4bOPiM button.navbar-toggler:focus {
  outline: none;
}
.cid-qR4z4bOPiM button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #cc2952;
}
.cid-qR4z4bOPiM button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all .2s;
}
.cid-qR4z4bOPiM button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all .15s;
}
.cid-qR4z4bOPiM button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all .15s;
}
.cid-qR4z4bOPiM button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all .2s;
}
.cid-qR4z4bOPiM nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-qR4z4bOPiM nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .25s;
}
.cid-qR4z4bOPiM nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all .25s;
}
.cid-qR4z4bOPiM nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-qR4z4bOPiM .collapsed.navbar-expand {
  flex-direction: column;
}
.cid-qR4z4bOPiM .collapsed .btn {
  display: flex;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse {
  display: none !important;
  padding-right: 0 !important;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse.collapsing,
.cid-qR4z4bOPiM .collapsed .navbar-collapse.show {
  display: block !important;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-qR4z4bOPiM .collapsed .navbar-collapse.show .navbar-nav {
  display: block;
  text-align: center;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-qR4z4bOPiM .collapsed .navbar-collapse.show .navbar-nav .nav-item {
  clear: both;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-qR4z4bOPiM .collapsed .navbar-collapse.show .navbar-buttons {
  text-align: center;
}
.cid-qR4z4bOPiM .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-qR4z4bOPiM .collapsed .navbar-collapse.show .navbar-buttons:last-child {
  margin-bottom: 1rem;
}
.cid-qR4z4bOPiM .collapsed button.navbar-toggler {
  display: block;
}
.cid-qR4z4bOPiM .collapsed .navbar-brand {
  margin-left: 1rem !important;
}
.cid-qR4z4bOPiM .collapsed .navbar-toggleable-sm {
  flex-direction: column;
  -webkit-flex-direction: column;
}
.cid-qR4z4bOPiM .collapsed .dropdown .dropdown-menu {
  width: 100%;
  text-align: center;
  position: relative;
  opacity: 0;
  display: block;
  height: 0;
  visibility: hidden;
  padding: 0;
  transition-duration: .5s;
  transition-property: opacity,padding,height;
}
.cid-qR4z4bOPiM .collapsed .dropdown.open > .dropdown-menu {
  position: relative;
  opacity: 1;
  height: auto;
  padding: 1.4rem 0;
  visibility: visible;
}
.cid-qR4z4bOPiM .collapsed .dropdown .dropdown-submenu {
  left: 0;
  text-align: center;
  width: 100%;
}
.cid-qR4z4bOPiM .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
  margin-top: 0;
  position: inherit;
  right: 0;
  top: 50%;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .3em;
  vertical-align: middle;
  content: "";
  border-top: .30em solid;
  border-right: .30em solid transparent;
  border-left: .30em solid transparent;
}
@media (max-width: 991px) {
  .cid-qR4z4bOPiM .navbar-expand {
    flex-direction: column;
  }
  .cid-qR4z4bOPiM img {
    height: 3.8rem !important;
  }
  .cid-qR4z4bOPiM .btn {
    display: flex;
  }
  .cid-qR4z4bOPiM button.navbar-toggler {
    display: block;
  }
  .cid-qR4z4bOPiM .navbar-brand {
    margin-left: 1rem !important;
  }
  .cid-qR4z4bOPiM .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .cid-qR4z4bOPiM .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
  }
  .cid-qR4z4bOPiM .navbar-collapse.collapsing,
  .cid-qR4z4bOPiM .navbar-collapse.show {
    display: block !important;
  }
  .cid-qR4z4bOPiM .navbar-collapse.collapsing .navbar-nav,
  .cid-qR4z4bOPiM .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
  }
  .cid-qR4z4bOPiM .navbar-collapse.collapsing .navbar-nav .nav-item,
  .cid-qR4z4bOPiM .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
  }
  .cid-qR4z4bOPiM .navbar-collapse.collapsing .navbar-buttons,
  .cid-qR4z4bOPiM .navbar-collapse.show .navbar-buttons {
    text-align: center;
  }
  .cid-qR4z4bOPiM .navbar-collapse.collapsing .navbar-buttons:last-child,
  .cid-qR4z4bOPiM .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
  }
  .cid-qR4z4bOPiM .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity,padding,height;
  }
  .cid-qR4z4bOPiM .dropdown.open > .dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
  }
  .cid-qR4z4bOPiM .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
  }
  .cid-qR4z4bOPiM .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
  }
}
@media (min-width: 767px) {
  .cid-qR4z4bOPiM .menu-logo {
    flex-shrink: 0;
  }
}
.cid-qR4z4bOPiM .navbar-collapse {
  flex-basis: auto;
}
.cid-qR4z4bOPiM .nav-link:hover,
.cid-qR4z4bOPiM .dropdown-item:hover {
  color: #7f1933 !important;
}
.cid-qR4z8xjKUt {
  background-image: url("../../../assets/images/mbr-1624x1080.jpg");
}
.cid-qR4z8xjKUt .mbr-section-subtitle {
  letter-spacing: .2rem;
}
.cid-qR4zigkUo7 {
  padding-top: 105px;
  padding-bottom: 90px;
  background-color: #efefef;
}
.cid-qR4zigkUo7 .card-box {
  padding: 0 2rem;
}
.cid-qR4zigkUo7 .mbr-section-btn {
  padding-top: 1rem;
}
.cid-qR4zigkUo7 .mbr-section-btn a {
  margin-top: 1rem;
  margin-bottom: 0;
}
.cid-qR4zigkUo7 h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
  padding-top: 2rem;
}
.cid-qR4zigkUo7 p {
  margin-bottom: 0;
  text-align: left;
  padding-top: 1.5rem;
}
.cid-qR4zigkUo7 .mbr-text {
  color: #767676;
}
.cid-qR4zigkUo7 .card-wrapper {
  height: 100%;
  padding-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
}
.cid-qR4zigkUo7 .card-wrapper:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;
}
.cid-qR4M4KR0lP {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #c1c1c1;
}
.cid-qR4M4KR0lP .mbr-section-subtitle {
  color: #767676;
}
.cid-qR4M4KR0lP .media-row {
  display: flex;
  justify-content: center;
}
.cid-qR4M4KR0lP .team-item {
  transition: all .2s;
  margin-bottom: 2rem;
}
.cid-qR4M4KR0lP .team-item .item-image img {
  width: 100%;
}
.cid-qR4M4KR0lP .team-item .item-name p {
  margin-bottom: 0;
}
.cid-qR4M4KR0lP .team-item .item-role p {
  margin-bottom: 0;
}
.cid-qR4M4KR0lP .team-item .item-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cid-qR4M4KR0lP .team-item .item-social .socicon {
  color: #232323;
  font-size: 17px;
}
.cid-qR4M4KR0lP .team-item .item-caption {
  background: #efefef;
}
.cid-qR4zrUA4V2 {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #55b4d4;
  background: linear-gradient(#55b4d4, #efefef);
}
.cid-qR4zrUA4V2 .carousel-control {
  background: #000;
}
.cid-qR4zrUA4V2 .mbr-section-subtitle {
  color: #767676;
}
.cid-qR4zrUA4V2 .carousel-item {
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-qR4zrUA4V2 .carousel-item .media-container-row {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.cid-qR4zrUA4V2 .carousel-item .wrap-img {
  text-align: center;
}
.cid-qR4zrUA4V2 .carousel-item .wrap-img img {
  max-height: 150px;
  width: auto;
  max-width: 100%;
}
.cid-qR4zrUA4V2 .carousel-controls {
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-qR4zrUA4V2 .carousel-controls .carousel-control {
  background: #000;
  border-radius: 50%;
  position: static;
  width: 40px;
  height: 40px;
  margin-top: 2rem;
  border-width: 1px;
}
.cid-qR4zrUA4V2 .carousel-controls .carousel-control.carousel-control-prev {
  left: auto;
  margin-right: 20px;
  margin-left: 0;
}
.cid-qR4zrUA4V2 .carousel-controls .carousel-control.carousel-control-next {
  right: auto;
  margin-right: 0;
}
.cid-qR4zrUA4V2 .carousel-controls .carousel-control .mbr-iconfont {
  font-size: 1rem;
}
.cid-qR4zrUA4V2 .cloneditem-1,
.cid-qR4zrUA4V2 .cloneditem-2,
.cid-qR4zrUA4V2 .cloneditem-3,
.cid-qR4zrUA4V2 .cloneditem-4,
.cid-qR4zrUA4V2 .cloneditem-5 {
  display: none;
}
.cid-qR4zrUA4V2 .col-lg-15 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
}
@media (min-width: 992px) {
  .cid-qR4zrUA4V2 .col-lg-15 {
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    width: 20%;
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.active.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.carousel-item-next {
    left: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.active.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.carousel-item-prev {
    left: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 > .carousel-item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides2 .cloneditem-1,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 .cloneditem-2,
  .cid-qR4zrUA4V2 .carousel-inner.slides2 .cloneditem-3 {
    display: block;
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.active.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.carousel-item-next {
    left: 0;
    -webkit-transform: translate3d(33.333333%, 0, 0);
    transform: translate3d(33.333333%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.active.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.carousel-item-prev {
    left: 0;
    -webkit-transform: translate3d(-33.333333%, 0, 0);
    transform: translate3d(-33.333333%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 > .carousel-item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides3 .cloneditem-1,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 .cloneditem-2,
  .cid-qR4zrUA4V2 .carousel-inner.slides3 .cloneditem-3 {
    display: block;
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.active.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.carousel-item-next {
    left: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.active.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.carousel-item-prev {
    left: 0;
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 > .carousel-item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides4 .cloneditem-1,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 .cloneditem-2,
  .cid-qR4zrUA4V2 .carousel-inner.slides4 .cloneditem-3 {
    display: block;
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.active.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.carousel-item-next {
    left: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.active.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.carousel-item-prev {
    left: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 > .carousel-item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides5 .cloneditem-1,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 .cloneditem-2,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 .cloneditem-3,
  .cid-qR4zrUA4V2 .carousel-inner.slides5 .cloneditem-4 {
    display: block;
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.active.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.carousel-item-next {
    left: 0;
    -webkit-transform: translate3d(16.666667%, 0, 0);
    transform: translate3d(16.666667%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.active.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.carousel-item-prev {
    left: 0;
    -webkit-transform: translate3d(-16.666667%, 0, 0);
    transform: translate3d(-16.666667%, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.carousel-item-left,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 > .carousel-item.active {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .cid-qR4zrUA4V2 .carousel-inner.slides6 .cloneditem-1,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 .cloneditem-2,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 .cloneditem-3,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 .cloneditem-4,
  .cid-qR4zrUA4V2 .carousel-inner.slides6 .cloneditem-5 {
    display: block;
  }
}
.cid-qSk293DIm9 {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #ffffff;
}
.cid-qSk293DIm9 .title {
  margin-bottom: 2rem;
}
.cid-qSk293DIm9 .mbr-section-subtitle {
  color: #767676;
}
.cid-qSk293DIm9 a:not([href]):not([tabindex]) {
  color: #fff;
  border-radius: 3px;
}
.cid-qSk293DIm9 a.btn-white:not([href]):not([tabindex]) {
  color: #333;
}
.cid-qSk293DIm9 .multi-horizontal {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  max-width: 100%;
}
.cid-qSk293DIm9 .input-group-btn {
  display: block;
  text-align: center;
}
.cid-qR4zxaahf3 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #2e2e2e;
}
.cid-qR4zxaahf3 .row-links {
  width: 100%;
  justify-content: center;
}
.cid-qR4zxaahf3 .social-row {
  width: 100%;
  justify-content: center;
}
.cid-qR4zxaahf3 .media-container-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cid-qR4zxaahf3 .media-container-row .foot-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}
.cid-qR4zxaahf3 .media-container-row .foot-menu li {
  padding: 0 1rem 1rem 1rem;
}
.cid-qR4zxaahf3 .media-container-row .foot-menu li p {
  margin: 0;
}
.cid-qR4zxaahf3 .media-container-row .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cid-qR4zxaahf3 .media-container-row .social-list .mbr-iconfont-social {
  font-size: 1.5rem;
  color: #ffffff;
}
.cid-qR4zxaahf3 .media-container-row .social-list .soc-item {
  margin: 0 .5rem;
}
.cid-qR4zxaahf3 .media-container-row .social-list a {
  margin: 0;
  opacity: .5;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}
.cid-qR4zxaahf3 .media-container-row .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-qR4zxaahf3 .media-container-row .social-list {
    justify-content: center;
    -webkit-justify-content: center;
  }
}
.cid-qR4zxaahf3 .media-container-row .row-copirayt {
  word-break: break-word;
  width: 100%;
}
.cid-qR4zxaahf3 .media-container-row .row-copirayt p {
  width: 100%;
}
